import Badge from "antd-mobile/es/components/badge";
import ProgressCircle from "antd-mobile/es/components/progress-circle";

export default function AchievementProgress({ levels, levelProgress, goal, prevLevel, showTitle = true, currentLevel, currentLevelIndex }) {
  return (<div className="px-4 py-5 sm:p-6">
    {showTitle && <dt className="text-base font-semibold text-gray-900">{goal.title}</dt>}
    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
      <div className="flex items-baseline text-2xl font-semibold text-theme-600">
        {goal.comparison}
        {/* <span className="ml-2 text-sm font-medium text-gray-500">from previousStat</span> */}
      </div>

      {/* <div
        className={clsx(
          // item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
          'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
        )}
      >
        {/* {item.changeType === 'increase' ? (
          <ArrowUpIcon
            className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
            aria-hidden="true"
          />
        ) : (
          <ArrowDownIcon
            className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
            aria-hidden="true"
          />
        )} */}

      {/* <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span> 
      change
  </div> */}
    </dd >
  </div >);
}

export function AchievementProgressOld({ levels, levelProgress, goal, prevLevel, showTitle = true, currentLevel, currentLevelIndex }) {
  return (
    <div className="py-6">
      <Badge
        className="flex h-10 w-10 items-center justify-center rounded-full font-lexend font-bold"
        bordered
        wrapperClassName="p-0 flex items-center justify-center"
        // content={goal.number}

        style={{ "--color": "var(--adm-color-warning)", "--adm-font-size-1": "1rem" }}
      >
        {levels.map((level, index) => {
          const size = 70 - (levels.length - 1 - index) * 16;
          const color =
            levels.length - 1 === index ? "var(--adm-color-primary)" : levels.length - 2 === index ? "var(--adm-color-warning)" : "var(--adm-color-success)";
          //if levelProgress[index] is NaN, then the badge should be empty
          const progress = isNaN(levelProgress[index]) ? 0 : levelProgress[index];
          return (
            <BadgeProgressCircle
              key={index}
              size={size}
              trackWidth={7}
              color={color}
              percent={progress * 100}
              icon={index === 0 && goal.icon}
              className={index !== levels.length - 1 && "!absolute"}
            />
          );
        })}
      </Badge>
      {/* <h2 className="title-font text-3xl font-medium text-gray-900">{goal.number}</h2> */}
      {showTitle && <p className="leading-relaxed">{goal.title}</p>}
      {/* {currentLevel && <p>{`${Math.round(currentLevel.number * (1 - levelProgress[currentLevelIndex]))} more to reach ${currentLevel.title}`}</p>} */}
      {/* {showTitle && prevLevel && <p>{prevLevel.title}</p>} */}
    </div>
  );
}

const BadgeProgressCircle = ({ size, trackWidth, color, percent, icon, className }) => {
  return (
    <ProgressCircle
      className={className}
      style={{
        "--fill-color": color,
        "--size": `${size}px`,
        "--track-width": `${trackWidth}px`,
        "--adm-color-background": "transparent",
      }}
      percent={percent}
    >
      <div style={{ height: `${size - 16}px` }}>{icon}</div>
      {/* {icon} */}
    </ProgressCircle>
  );
};
